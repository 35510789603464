<template>
  <div class="col">
    <!-- groups table  -->

    <server-table
      :count="count"
      :DataItems="usersData"
      @Refresh="Refresh"
      v-loading="$store.getters['user/usersLoad']"
      :title="$t('Group_requests')"
      :useFilter="false"
      :enable_export="false"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="group_name"
          sortable
          :label="$t('Class_name')"
          min-width="120"
        >
        </el-table-column>

        <el-table-column
          prop="title"
          sortable
          :label="$t('Global.course_name')"
          min-width="120"
        >
        </el-table-column>

        <el-table-column
          prop="teacher_name"
          sortable
          :label="$t('teacher_name')"
          min-width="120"
        >
        </el-table-column>

        <el-table-column prop="" label="_" width="180">
          <template slot-scope="scope">
            <div
              class="td-actions d-flex justify-content-around"
              style="gap: 10px"
            >
              <el-button
                type="primary"
                @click="
                  changeStatus({
                    group_id: scope.row.group_id,
                    status: { status: 1 },
                  })
                "
              >
                {{ $t("confirm") }}
              </el-button>
              <el-button
                type="danger"
                @click="
                  changeStatus({
                    group_id: scope.row.group_id,
                    status: { status: 0 },
                  })
                "
              >
                {{ $t("cancel") }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Button from "../../components/Button.vue";

export default {
  components: { ServerTable, Button },
  name: "request",
  data() {
    return {};
  },
  computed: {
    usersData() {
      return this.$store.getters["user/requests"];
    },
    count() {
      return this.$store.getters["user/requests"].length;
    },
  },

  methods: {
    Refresh(query) {
      this.$store.dispatch("user/groupsRequests");
    },
    changeStatus(query) {
      this.$store.dispatch("user/changeGroupStatus", query).then((_) => {
        this.Refresh(null);
      });
    },
  },
};
</script>

<style scoped>
::v-deep .cell {
  text-align: right;
  word-break: break-word;
}
</style>
